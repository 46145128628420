@use "../variables" as *;

.svc-link__button {
  @include ctrDefaultBoldFont;
  border-radius: var(--ctr-actionbar-button-corner-radius, calcSize(12.5));
}

.svc-question-link__set-button {
  cursor: pointer;
}
.svc-link-value-button {
  --thm-margin-inline-start: calc(-1 * var(--ctr-actionbar-button-padding-left-medium-text));
  margin-inline-start: var(--thm-margin-inline-start, calcSize(-2));
}
.svc-question-link__clear-button {
  color: $red;
  cursor: pointer;
}
.svc-question-link__clear-button:focus,
.svc-question-link__clear-button:hover {
  background-color: $red-light;
}
