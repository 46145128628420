@import styles/helpers

.nav
    display: flex
    justify-content: center
    margin-bottom: 32px
    .link
        display: flex
        padding: 6px 12px
        border-radius: 14px
        background: none
        +button-2
        color: $n4
        transition: all .2s
        &:hover
            color: $n3
            +dark
                color: $n8
        &.active
            background: $n3
            color: $n8
            +dark
                background: $n3
                color: $n8
        &:not(:last-child)
            margin-right: 24px


.wrapper
    align-items: center
    background-color: #f0f0f0
    display: flex
    justify-content: center
    min-height: 100vh
    padding: 24px
    width: 100%


.container
    flex: 1 
.edit
    text-align: center
    margin-bottom: 16px
.editBtn    
    cursor: pointer
    text-decoration: underline  
    color: $p1 
    &:hover
        color: $p1 


.title   
    font-weight: 600
    font-size: 24px
    line-height: 32px
    color: #17191f

.sub_title
     font-weight: 400
     font-size: 14px
     line-height: 20px
     color: #747e99

.inner
   background: #ffffff
   border-radius: 8px
   padding:48px 32px
   width: 530px
   display: flex
   flex-direction: column
   gap: 16px
   border-radius: 8px
   min-height: 475px
   +m
      width: unset
   

.line
    display: flex
    justify-content: center
    gap: 10px
    .field
        &:first-child
            flex-shrink: 0
            width: 128px
            margin-right: 8px
        &:nth-child(2)
            flex-grow: 1

.foot
    display: flex
    justify-content: space-between
    margin-top: 16px
    +caption-bold-2
    .link
        color: $p1
        transition: color .2s
        &:hover
            color: darken($p1, 10)

.scan
    +caption-bold-2
    color: $n4
    transition: color .2s
    &:hover
        color: $n2

.button
    width: 274px

.otpInput 
    background-color: transparent
    border: 1px solid $n6
    border-radius: 10px
    color: #000000
    font-weight: 500
    font-size: 14px
    height: 58px
    text-align: center
    width: 58px

.verify_button
    display: flex
    justify-content: center
    width: 100%
    margin-top: 12px

.otp    
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.otp_detail
    font-weight: 600
    font-size: 32px
    +m
        font-size: 26px
        text-align: center

.back_button
    width: 274px
    background: grey
    &:hover
        background: rgba(245, 175, 69, 0.06) 


      