@use "../../variables" as *;

svc-tab-test {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-test-tab--empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  gap: 0px;
}

.svc-test-tab__content .svc-plugin-tab__content {
  .sv-root-modern .sv-completedpage,
  .sv_default_css .sv_completed_page {
    margin: 0;
    border: 0;
    background-color: $background-dim;
  }

  .sv_default_css .sv_body {
    border: 0;
  }

  .svc-preview__test-again {
    width: calcSize(33);
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calcSize(12);
    margin-top: 8px;
  }
}

.svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {
  height: calc(100% - calc(6 * #{$base-unit}));
}

.svc-test-tab__content-actions {
  position: relative;

  .sv-action-bar {
    padding: var(--ctr-preview-pager-padding-top, calcSize(1)) var(--ctr-preview-pager-padding-right, 0px) var(--ctr-preview-pager-padding-bottom, calcSize(1)) var(--ctr-preview-pager-padding-left, 0px);
    box-shadow: inset 0 var(--ctr-preview-pager-border-width-top, 1px) 0 0 var(--ctr-preview-pager-border-color, $border);
    background: var(--ctr-preview-pager-background-color, $background);
    width: 100%;
  }

  .sv-action-bar.sv-action-bar--pages {
    left: 0;
    right: 0;
    justify-content: center;
    gap: calcSize(1);

    .sv-action__content {
      padding: 0;
    }

    .sv-action-bar-item {
      margin: 0;

      use {
        fill: var(--ctr-survey-question-panel-toolbar-item-icon-color, $foreground-light);
      }
    }

    .sv-action-bar-item:not(.sv-action-bar-item--pressed):enabled {
      &:focus {
        background: var(--ctr-survey-question-panel-toolbar-item-background-color-selected, $background-dim);
      }

      &:hover {
        background: var(--ctr-survey-question-panel-toolbar-item-background-color-hovered, $background-dim);
      }
    }

    .sv-action-bar-item--pressed:not(.sv-action-bar-item--active) {
      background: var(--ctr-survey-question-panel-toolbar-item-background-color-selected, $background-dim);
    }

    .sv-action-bar-item__title {
      color: var(--ctr-survey-question-panel-toolbar-item-text-color, $foreground);
    }
  }

  .sv-action-bar-item {
    @include ctrSmallBoldFont;
    width: 100%;
    height: calcSize(4);
    transition: background-color $creator-transition-duration;
  }

  .svc-page-selector {
    max-width: 50%;

    .sv-action-bar-item__title {
      @include textEllipsis;

      color: $foreground;
      display: inline-block;
    }
  }
}

.svc-page-invisible {
  .svc-list__item-body {
    padding-block-start: var(--ctr-list-item-padding-top, calcSize(1));
    padding-block-end: var(--ctr-list-item-padding-bottom, calcSize(1));
    padding-inline-start: var(--ctr-list-item-padding-left, calcSize(2));
    padding-inline-end: var(--ctr-list-item-padding-right-with-label-icon, calcSize(1.5));
  }
  .svc-list-item__marker-icon {
    padding: 0;
    opacity: var(--ctr-list-item-icon-opacity-label, 0.5);
    width: var(--ctr-list-item-icon-width, calcSize(3));
    height: var(--ctr-list-item-icon-height, calcSize(3));
  }
}
.svc-test-tab__content .sd-body--empty {
  @include ctrDefaultFont;
  color: $foreground;
  text-align: center;
  overflow: hidden;
  position: static;
  display: flex;
  flex-direction: column;
}

.svc-preview-pager__item {
  @include defaultActionButtonSettings;

  display: flex;
  max-width: 100%;
  padding: var(--ctr-preview-pager-item-padding-top-with-text, calcSize(1))
    var(--ctr-preview-pager-item-padding-right-with-text, calcSize(1))
    var(--ctr-preview-pager-item-padding-bottom-with-text, calcSize(1))
    var(--ctr-preview-pager-item-padding-left-with-text, calcSize(1));
  justify-content: center;
  align-items: center;
  border-radius: var(--ctr-preview-pager-item-corner-radius, calcSize(0.5));
  background-color: transparent;

  &:hover {
    background: var(--ctr-survey-question-panel-toolbar-item-background-color-hovered, $background-dim);
  }
  &:disabled {
    opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-disabled, 0.25);
  }

  &.svc-preview-pager__item--pressed {
    opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-pressed, 0.5);
    background: var(--ctr-survey-question-panel-toolbar-item-background-color-hovered, $background-dim);
  }
  &.svc-preview-pager__item--active {
    background: var(--ctr-survey-question-panel-toolbar-item-background-color-selected, $secondary-light);
  }
}

.svc-preview-pager__item--icon {
  padding: var(--ctr-preview-pager-item-padding-top, calcSize(0.5))
    var(--ctr-preview-pager-item-padding-right, calcSize(0.5))
    var(--ctr-preview-pager-item-padding-bottom, calcSize(0.5))
    var(--ctr-preview-pager-item-padding-left, calcSize(0.5));

  &:hover {
    background: var(--ctr-survey-question-panel-toolbar-item-background-color-hovered, $background-dim);
  }

  &:disabled {
    opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-disabled, 0.25);

    use {
      fill: var(--ctr-survey-question-panel-toolbar-item-icon-color-disabled, $foreground-dim-light);
    }
  }

  &.svc-preview-pager__item--pressed {
    opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-pressed, 0.5);
    background: var(--ctr-survey-question-panel-toolbar-item-background-color-hovered, $background-dim);

    use {
      fill: var(--ctr-survey-question-panel-toolbar-item-icon-color-pressed, $foreground-dim-light);
    }
  }

  &.svc-preview-pager__item--active {
    background: var(--ctr-survey-question-panel-toolbar-item-background-color-selected, $secondary-light);
  }
}

.svc-preview-pager-item__icon {
  box-sizing: border-box;
  display: flex;
  width: var(--ctr-preview-pager-item-icon-container-width, calcSize(3));
  height: var(--ctr-preview-pager-item-icon-container-height, calcSize(3));
  padding: var(--ctr-preview-pager-item-icon-padding-top, calcSize(0.5))
    var(--ctr-preview-pager-item-icon-padding-right, calcSize(0.5))
    var(--ctr-preview-pager-item-icon-padding-bottom, calcSize(0.5))
    var(--ctr-preview-pager-item-icon-padding-left, calcSize(0.5));
  justify-content: center;
  align-items: center;

  use {
    fill: var(--ctr-survey-question-panel-toolbar-item-icon-color, $secondary);
  }
}

.svc-preview-pager-item__title {
  @include ctrSmallBoldFont;
  @include textEllipsis;
  color: var(--ctr-survey-question-panel-toolbar-item-text-color, $foreground);
}

.svc-footer-bar {
  .svc-preview-pager__item {
    --thm-menu-toolbar-button-gap: calc(var(--ctr-menu-toolbar-gap) / 2);
    margin: 0 var(--thm-menu-toolbar-button-gap, calcSize(1));
  }

  .svc-preview-pager-item__icon {
    width: var(--ctr-menu-toolbar-button-icon-width, calcSize(3));
    height: var(--ctr-menu-toolbar-button-icon-height, calcSize(3));
    box-sizing: content-box;

    use {
      fill: var(--ctr-menu-toolbar-button-icon-color, $foreground-light);
    }
  }
}
