@use "../../variables" as *;

svc-tab-json-editor-ace {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-json-editor-tab__content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.svc-json-editor-tab__ace-editor {
  height: 100%;
}