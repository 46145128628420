@use "../variables" as *;

.svc-question__dropdown-choices {
  margin-top: calcSize(2);
}

.svc-question__dropdown-choice--collapsed:nth-last-child(1) {
  opacity: 0.25;
}


.svc-question__dropdown-choice--collapsed:nth-last-child(2) {
  opacity: 0.5;
}


.svc-question__dropdown-choice--collapsed:nth-last-child(3) {
  opacity: 0.75;
}

.svc-question__content>.svc-question__dropdown-choices--wrapper {
  width: calc(100% + calcSize(8));
  overflow-x: auto;
  margin: 0 calcSize(-4);

  &>div {
    width: calc(100% - calcSize(8));
    display: inline-block;
    min-width: calcSize(15);
    margin: 0 calcSize(4);
  }

  .svc-action-button {
    @include lbrDefaultBoldFont;
    margin-left: var(--ctr-survey-radio-button-group-show-more-button-margin-left, calcSize(6.75));
    margin-top: var(--ctr-survey-radio-button-group-show-more-button-margin-top, calcSize(1));
    padding: var(--ctr-survey-action-button-padding-top, calcSize(0.5))
      var(--ctr-survey-action-button-padding-right, calcSize(2))
      var(--ctr-survey-action-button-padding-bottom, calcSize(0.5))
      var(--ctr-survey-action-button-padding-left, calcSize(2));
    border-radius: var(--ctr-survey-action-button-corner-radius, 1024px);

    cursor: pointer;
  }
}