@use "../variables" as *;

.svc-image-item-value-wrapper {
  display: inline-block;
  position: relative;
}

.svc-image-item-value--new {
  display: none;
  height: max-content;

  .sd-imagepicker__image {
    box-sizing: border-box;
    border: 1px dashed $border;
  }
}

.svc-image-item-value--file-dragging {
  .sd-imagepicker__image {
    border: 2px solid $primary;
    background-color: $primary-light;
  }

  .svc-image-item-value__item {
    position: relative;
    z-index: 2;
  }
}

.svc-question__content--selected .svc-image-item-value--new {
  display: inline-block;
}

.svc-image-item-value-controls {
  position: absolute;
  top: calcSize(1);
  right: calcSize(1);
  z-index: 2;
}

.svc-image-item-value--new .svc-image-item-value-controls {
  top: calc(50% - 3 * #{$base-unit});
  left: calc(50% - 3 * #{$base-unit});
}

.svc-image-item-value--new .svc-image-item-value-controls__button {
  background-color: transparent;
  transition: background-color $creator-transition-duration;
}

.svc-image-item-value-controls__add {
  background-color: transparent;
  transition: background-color $creator-transition-duration;

  use {
    fill: $primary;
    transition: fill $creator-transition-duration;
  }

  &:hover,
  &:focus {
    background-color: $primary-light;
  }
}

.svc-choose-file-input {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.svc-image-item-value__item {
  .sd-imagepicker__item.sd-imagepicker__item {
    width: 100%;
  }
}

.svc-image-item-value-controls__drag-area-indicator {
  display: none;
  cursor: move;
  position: absolute;
  top: calcSize(1);
  left: calcSize(1);
}

.svc-question__content--selected .svc-image-item-value-wrapper:hover {
  .svc-image-item-value-controls__drag-area-indicator {
    display: block;
  }
}

.svc-image-item-value-wrapper--ghost {
  .svc-image-item-value-wrapper__ghost {
    display: block;
  }

  .svc-image-item-value-wrapper__content {
    display: none;
  }
}

.svc-image-item-value-wrapper__ghost {
  display: none;
  background: $background-dim;
}

.svc-image-item-value--single {
  width: 100%;

  .sd-imagepicker__image {
    height: calcSize(30);
  }
}

.svc-image-item-value__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}