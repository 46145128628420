@use "../../variables.scss" as *;

.spg-action-bar {
  display: flex;
  box-sizing: content-box;
  position: relative;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
  white-space: nowrap;

}

.spg-action-button {
  @include ctrDefaultBoldFont;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: var(--ctr-actionbar-button-padding-top-large-icon, calcSize(1))
    var(--ctr-actionbar-button-padding-right-large-icon, calcSize(1))
    var(--ctr-actionbar-button-padding-bottom-large-icon, calcSize(1))
    var(--ctr-actionbar-button-padding-left-large-icon, calcSize(1));
  box-sizing: border-box;
  border: none;
  color: var(--ctr-actionbar-button-text-color, $primary);
  border-radius: var(--ctr-actionbar-button-corner-radius, calcSize(12.5));
  background-color: transparent;
  cursor: pointer;
  transition: background-color $creator-transition-duration, opacity $creator-transition-duration;

  &:hover,
  &:focus {
    opacity: 1;
    outline: none;
    background-color: var(--ctr-actionbar-button-background-color-hovered, $primary-light);

    use {
      fill: var(--ctr-actionbar-button-icon-color-hovered, $primary);
    }
  }
}

.spg-action-button--icon {
  padding: var(--ctr-actionbar-button-padding-top-large-icon, calcSize(1))
    var(--ctr-actionbar-button-padding-right-large-icon, calcSize(1))
    var(--ctr-actionbar-button-padding-bottom-large-icon, calcSize(1))
    var(--ctr-actionbar-button-padding-left-large-icon, calcSize(1));
}
.spg-action-button__icon {
  display: block;
  width: var(--ctr-actionbar-button-icon-width, calcSize(3));
  height: var(--ctr-actionbar-button-icon-height, calcSize(3));

  use {
    fill: var(--ctr-actionbar-button-icon-color, $foreground-light);
    transition: fill $creator-transition-duration;
  }
}

.spg-action-button--danger {
  color: var(--ctr-library-action-button-text-color-negative, $red);

  &:hover,
  &:focus {
    background-color: var(--ctr-library-action-button-background-color-negative-hovered, $red-light);

    use {
      fill: var(--ctr-library-action-button-icon-color-negative, $red);
    }
  }
}

button.spg-action-button--large {
  padding: calcSize(0.5) calcSize(2);
}

.spg-action-button:disabled {
  opacity: var(--ctr-actionbar-button-opacity-disabled, 0.25);
  pointer-events: none;
  cursor: default;
}

.spg-action-button--muted {
  opacity: var(--ctr-actionbar-button-opacity-muted, 0.5);
}

.spg-action-button:active {
  opacity: var(--ctr-actionbar-button-opacity-pressed, 0.5);
}

.spg-action-button--text {
  padding: calcSize(0.5) calcSize(2);
}