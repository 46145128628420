@use "../../variables" as *;

.svc-property-grid-placeholder {
  display: flex;
  padding: var(--ctr-property-grid-placeholder-padding-top, 96px)
    var(--ctr-property-grid-placeholder-padding-right, 48px) var(--ctr-property-grid-placeholder-padding-bottom, 0px)
    var(--ctr-property-grid-placeholder-padding-left, 48px);
  flex-direction: column;
  align-items: center;
  gap: var(--ctr-property-grid-placeholder-image-margin-top, 24px);
  flex: 1 0 0;
  align-self: stretch;
  background: var(--ctr-property-grid-form-background-color, #f3f3f3);
}
.svc-property-grid-placeholder__header {
  display: flex;
  max-width: var(--ctr-property-grid-placeholder-text-max-width, 256px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--ctr-property-grid-placeholder-text-gap, 8px);
}
.svc-property-grid-placeholder__title {
  @include ctrDefaultBoldFont;
  color: var(--ctr-property-grid-placeholder-text-title-color, rgba(0, 0, 0, 0.91));
  text-align: center;
  display: inline-block;
}
.svc-property-grid-placeholder__description {
  @include ctrSmallFont;
  color: var(--ctr-property-grid-placeholder-text-description-color, rgba(0, 0, 0, 0.45));
  text-align: center;
  display: inline-block;
}
.svc-property-grid-placeholder__content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.svc-property-grid-placeholder__gap {
  max-width: 192px;
  flex: 1 0 0;
  width: 100%;
}
.svc-property-grid-placeholder__image {
  display: flex;
  max-width: 192px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background: url("../../images/image-panel-placeholder.png") 50% / contain no-repeat;
  width: 100%;
  overflow: hidden;
  height: 86px;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"],
.svc-creator__side-bar--left {
  .svc-property-grid-placeholder__image {
    transform: rotateY(180deg);
  }
}

.svc-creator__side-bar--left .svc-property-grid-placeholder__content {
  flex-direction: row-reverse;
}
