@use "../../variables" as *;

.spg-color-editor__color-input {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  top: 100%;
  transform: translateY(-50%);
}

.svc-list__item-body {
  // display: flex;
  // gap: var(--ctr-list-item-gap, calcSize(2));
  // padding: var(--ctr-list-item-padding-top-with-icon, calcSize(1)) var(--ctr-list-item-padding-right, calcSize(8))
  //   var(--ctr-list-item-padding-bottom-with-icon, calcSize(1)) var(--ctr-list-item-padding-left, calcSize(2));

  .spg-color-editor__color-swatch {
    margin-left: 0;
    flex: 0 1 auto;
  }
}

.spg-color-editor__color-swatch {
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: var(--ctr-list-item-color-swatch-corner-radius, 3px);
  width: var(--ctr-list-item-color-swatch-width, calcSize(4));
  height: var(--ctr-list-item-color-swatch-height, calcSize(3));
  box-sizing: border-box;
  border: 1px solid var(--ctr-list-item-color-swatch-border-color, $border-inside);
  margin-left: var(--ctr-editor-color-swatch-margin-left, calcSize(1.5));

  .sv-svg-icon {
    position: absolute;
    width: var(--ctr-editor-color-swatch-icon-width, calcSize(2));
    height: var(--ctr-editor-color-swatch-icon-height, calcSize(2));
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: $background;
  }
}
.spg-question--disabled .spg-color-editor {
  background-color: var(--ctr-editor-background-color-disabled, $background-dim);
}
.spg-color-editor__color-swatch--disabled {
  .sv-svg-icon {
    opacity: 0.25;
  }
}

.spg-color-editor__color-swatch--default {
  .sv-svg-icon {
    opacity: 1;
  }
}

.spg-color-editor .spg-input__edit-button+* {
  margin-inline-end: calc(0px - var(--ctr-editor-gap, 4px));
}