@import styles/helpers

.wrapper
    align-items: center
    display: flex
    justify-content: center
    // min-height: 90vh
    width: 100%

.inner_wrapper
    max-width: 576px
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    +m
        max-width: 576px
        width: 100%

.inner
   display: flex
   flex-direction: column
   gap: 16px
   +m
      width: unset
    

.title   
    font-weight: 600
    font-size: 24px
    line-height: 32px
    color: #17191f

.sub_title
     font-weight: 400
     font-size: 14px
     line-height: 20px
     color: #747e99
.lists
    display: flex
    flex-direction: column
    gap: 16px
    flex: 1

.list_item
     display: flex
     gap: 8px
     align-items: center
     background: rgba(51, 184, 122, 0.06)
     border-radius: 8px
     padding: 16px
     cursor: pointer
     color: $n13
     &:hover
       background: rgba(245, 175, 69, 0.06)
       color: $s5

.list_item_disabled 
    cursor: not-allowed

.left_icon
    background: rgba(51, 184, 122, 0.12)
    border-radius: 24px
    width: 40px
    height: 40px
    display: grid
    place-items: center
    font-size: 24px
   

.right_icon
     font-size: 24px

.method_title
   text-align: initial
   font-weight: 600
   font-size: 16px
   line-height: 24px
   color: #464c5c
   flex: 1

.back_btn
    display: flex
    gap: 8px
    width: 40%
    align-items: center
    background: gray
    border-radius: 8px
    padding: 16px
    text-align: center
    height: 40px
    align-self: center
    cursor: pointer
    color: $n13
    &:hover
       background: rgba(245, 175, 69, 0.06)
       color: $s5

.edit_Btn    
    cursor: pointer
    text-decoration: underline  
    color: $p1
    &:hover
        color: $p1
        
       