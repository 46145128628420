@use "../../variables.scss" as *;

.svc-side-bar {
  .svc-search {
    border-bottom: 1px solid var(--ctr-property-grid-search-border-color, $border);
    background: var(--ctr-property-grid-search-background-color, $background-dim);
    gap: var(--ctr-property-grid-search-gap, calcSize(1));
  
    padding: var(--ctr-property-grid-search-padding-top, calcSize(2))
      var(--ctr-property-grid-search-padding-right, calcSize(2))
      var(--ctr-property-grid-search-padding-bottom, calcSize(2))
      var(--ctr-property-grid-search-padding-left, calcSize(2));
  }
  
  .svc-search__input {
    color: var(--ctr-property-grid-search-text-color, $foreground);
  }
  
  .svc-search__input::placeholder {
    color: var(--ctr-property-grid-search-text-color-placeholder, $foreground-light);
  }
  
  .svc-search__bar-item:not(.sv-action-bar-item--pressed) {
    &:hover:enabled,
    &:focus:enabled {
      background-color: var(--ctr-property-grid-search-button-background-color-hovered, $background-dim);
    }
  }
  
  .svc-search__toolbar .sv-action-bar-item__icon use {
    fill: var(--ctr-property-grid-search-button-icon-color, $foreground-light);
  }
  
  .svc-search__toolbar-counter {
    color: var(--ctr-property-grid-search-count-text-color, $foreground-light);
  }
  
  .svc-search__search-icon {
    width: var(--ctr-property-grid-search-icon-width, calcSize(3));
    height: var(--ctr-property-grid-search-icon-height, calcSize(3));
  
    .sv-svg-icon {
      width: var(--ctr-property-grid-search-icon-width, calcSize(3));
      height: var(--ctr-property-grid-search-icon-height, calcSize(3));
  
      use {
        fill: var(--ctr-property-grid-search-icon-color, $foreground-light);
      }
    }
  }
}