@use "../variables" as *;

.svc-context-container {
  display: flex;
  gap: calcSize(1);
  width: max-content;
}

.svc-context-button {
  display: flex;
  box-sizing: border-box;
  background-color: var(--ctr-survey-contextual-button-background-color, $background);
  border-radius: var(--ctr-survey-contextual-button-corner-radius, 50%);
  width: var(--ctr-survey-contextual-button-width, calcSize(6));
  height: var(--ctr-survey-contextual-button-height, calcSize(6));
  padding: var(--ctr-survey-contextual-button-padding-top, calcSize(1.5))
    var(--ctr-survey-contextual-button-padding-right, calcSize(1.5))
    var(--ctr-survey-contextual-button-padding-bottom, calcSize(1.5))
    var(--ctr-survey-contextual-button-padding-left, calcSize(1.5));

  cursor: pointer;
  outline: none;

  .sv-svg-icon {
    --thm-button-contextual-button-icon-width: calc(
      var(--ctr-survey-contextual-button-width) - var(--ctr-survey-contextual-button-padding-right) -
        var(--ctr-survey-contextual-button-padding-left)
    );
    --thm-button-contextual-button-icon-height: calc(
      var(--ctr-survey-contextual-button-height) - var(--ctr-survey-contextual-button-padding-top) -
        var(--ctr-survey-contextual-button-padding-bottom)
    );
    width: var(--thm-button-contextual-button-icon-width, calcSize(3));
    height: var(--thm-button-contextual-button-icon-height, calcSize(3));
  }

  use {
    fill: $foreground-light;
    transition: fill $creator-transition-duration;
  }
}

.svc-context-button {

  &:hover,
  &:focus {
    use {
      fill: $primary;
    }
  }
}

.svc-context-button--danger {

  &:hover,
  &:focus {
    use {
      fill: $red;
    }
  }
}