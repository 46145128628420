@use "../variables" as *;

.svc-scroll__wrapper {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: 0;
  display: flex;
  flex-direction: column;
}

.svc-scroll__scroller {
  box-sizing: border-box;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.svc-scroll__scrollbar {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: fit-content;
  margin-inline-start: -1px;
  visibility: hidden;
  z-index: 30;
  inset-inline-end: 0;
  inset-inline-start: initial;
  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: $border transparent;
  }
}

.svc-scroll__wrapper:hover .svc-scroll__scrollbar {
  visibility: visible;
}

.svc-scroll__scrollbar-sizer {
  width: 1px;
}

.svc-scroll__container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}