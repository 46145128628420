@use "../../variables.scss" as *;

.spg-question-group {
  .spg-row {
    margin-top: 0;
    &:not(:first-of-type) {
      margin-top: calcSize(1);
    }
  }
}
